import React from 'react';
import AdminRoutes from './Routes/AdminRoutes';
import Routing from './Routes/Routes.routes';


const App = () => {
  return (
    <div>
      {/* <AdminRoutes /> */}
      <Routing/>
    </div>
  );
}

export default App;
